<table id="storageFileList" class="table table--hover table--selector" [ngClass]="storageService.isMultipleSelector() ? 'table--multiple-selector' : 'table--single-selector'" [shown]="filesDetails.code!==202 && filesDetails.code!==404 && fileListVisible">
  <thead class="table-header">
    <tr class="table-header__row u_clickable">
      <th class="col-sm-6 table-header__cell">
        <div class="flex-row">
          <mat-checkbox (click)="selectAllCheckboxes.emit()" [(ngModel)]="selectAll" [shown]="storageService.isMultipleSelector()"></mat-checkbox>
          <div id="tableHeaderName" (click)="changeSort('name')">
            Name
            @if (search.sortBy === 'name') {
              <i [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" class="fa" [ngClass]="{false: 'fa-caret-up', true: 'fa-caret-down'}[search.reverse]"></i>
            }
          </div>
        </div>
      </th>
      @if (!storageService.isFolderFilter()) {
        <th class="col-sm-2 table-header__cell hidden-xs" (click)="changeSort('extension')">
          Type
          @if (search.sortBy === 'extension') {
            <i [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" class="fa" [ngClass]="{false: 'fa-caret-up', true: 'fa-caret-down'}[search.reverse]"></i>
          }
        </th>
        <th class="col-sm-2 table-header__cell visible-lg" (click)="changeSort('size')">
          Size
          @if (search.sortBy === 'size') {
            <i [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" class="fa" [ngClass]="{false: 'fa-caret-up', true: 'fa-caret-down'}[search.reverse]"></i>
          }
        </th>
        <th class="col-sm-2 table-header__cell visible-lg" (click)="changeSort('dateModified')">
          Modified
          @if (search.sortBy === 'dateModified') {
            <i [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" class="fa" [ngClass]="{false: 'fa-caret-up', true: 'fa-caret-down'}[search.reverse]"></i>
          }
        </th>
      }
    </tr>
  </thead>
  <tbody class="table-body">
    @for (file of storageUtilsService.filterFiles(files, search, storageService.storageFull); track file) {
      <tr
        [ngClass]="{'active': file.selected || file.isChecked, 'table-body__row--blocked-file': file.isThrottled, 'table-body__row--no-select': !storageService.canSelect(file), 'table-body__row--disabled': storageService.isDisabled(file) }"
        class="table-body__row"
        (click)="triggerFileClick(file);">
        @if (storageUtilsService.fileIsFolder(file)) {
          <td class="table-body__cell">
            <div class="flex-row">
              @if (storageService.canSelect(file)) {
                <mat-checkbox [ngModel]="file.selected || file.isChecked"></mat-checkbox>
              }
              <span class="u_ellipsis-lg folder" title="{{file.name | storageFileName}}"><strong>{{file.name | storageFileName:folderPath}}</strong></span>
            </div>
          </td>
        }
        @else {
          <td class="col-sm-6 table-body__cell">
            <div class="flex-row">
              @if (storageService.canSelect(file)) {
                <mat-checkbox [ngModel]="file.selected || file.isChecked"></mat-checkbox>
              }
              <span class="file u_ellipsis-lg" data-toggle="tooltip" title="{{file.name | storageFileName}}">
                <i aria-label="File is throttled" class="fa fa-exclamation u_icon-red" [shown]="file.isThrottled && !storageUtilsService.fileIsFolder(file)"></i>
                <strong>{{file.name | storageFileName:folderPath}}</strong>
              </span>
            </div>
            <div [shown]="file.showThrottledCallout && !storageUtilsService.fileIsFolder(file)" class="callout callout-red" (click)="$event.stopPropagation();file.showThrottledCallout=false;">
              <span class="arrow"></span>
              <p>This file is being requested too many times and is blocked.</p>
              <p>If you need assistance please click the <strong>Need Help?</strong> menu item above.</p>
            </div>
          </td>
        }
        @if (!storageService.isFolderFilter()) {
          <td class="col-sm-2 table-body__cell hidden-xs">
            <span> {{file.name | storageFileType}}</span>
          </td>
          <td class="col-sm-2 table-body__cell visible-lg">
            <span> {{file.size | storageFileSize}}</span>
          </td>
          <td class="col-sm-2 table-body__cell visible-lg">
            <span> {{file.updated?.value | date:'d-MMM-yyyy h:mm a'}}</span>
          </td>
        }
      </tr>
    }
  </tbody>
</table>
